<template>
  <div class="up" @click="up">
    <img src="@/assets/img/up.png" alt="" />
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {
    up() {
      this.$store.state.bar.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    },
  },
};
</script>
    
<style scoped>
.up {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  z-index: 999;
  right: 1%;
  bottom: 5%;
  background: #fff;
  cursor: pointer;
  border-radius: 10px;
}
.up img {
  width: 50%;
}
</style>