import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    axios: "http://",
    load: false,
    loadNum: 0,
    currentPage: 0,
    topBol: true,
    scrollY: 0,
    page1: 0,
    page2: 0,
    page3: 0,
    page4: 0,
    bar: null,
    topInfoBol: false,
    swiper: null,
    refresh: 0,
  },
  mutations: {},
  actions: {},
  modules: {},
});
