<template>
  <div class="btm">
    <div class="head">
      <span></span>
      <p>全国免费咨询热线</p>
      <span></span>
    </div>
    <p class="phone">0371-85510169</p>
    <div class="vx">
      <img src="@/assets/img/vx-qr-code.jpg" alt="" />
      <span>国立微智能微信</span>
      <span style="opacity: 0.4; color: #333">Copyright © 2020 wzn</span>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
};
</script>
    
<style scoped>
.btm {
  background: #fafafa;
  padding: 30px 0 120px;
}
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.head span {
  flex: 1;
  margin: 0 10%;
  border-bottom: 1px #ccc solid;
}
.phone {
  text-align: center;
  color: #01aeeb;
  margin-bottom: 5vw;
  font-size: 7vw;
  font-family: avant;
  padding-top: 1vw;
}
.vx {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #01aeeb;
}
.vx img {
  width: 30%;
}
</style>