<template>
  <div class="btm">
    <div class="left">
      <img src="@/assets/img/logo1.png" alt="" height="30" />
      <p style="color: #000; margin: 30px 0 20px">
        河南省郑州市金水区杨金路外包产业园天亨I号楼1-4层
      </p>
      <p style="margin: 30px 0 20px">
        <a href="https://beian.miit.gov.cn/" target="_blank" 
          >豫ICP备2020033461号-1</a
        >
      </p>
      <p style="color: #c0c0c0">Copyright © 2020 wzn</p>
    </div>
    <div class="center">
      <div @click="$router.push('/Product')">
        <img src="@/assets/img/btm-1.png" alt="" width="40" height="40" />
        <p>产品中心</p>
      </div>
      <div>
        <img
          @click="$router.push('/Solve')"
          src="@/assets/img/btm-2.png"
          alt=""
          width="40"
          height="40"
        />
        <p>解决方案</p>
      </div>
      <div>
        <img
          @click="$router.push('/Use')"
          src="@/assets/img/btm-3.png"
          alt=""
          width="40"
          height="40"
        />
        <p>应用案例</p>
      </div>
      <div>
        <img
          @click="$router.push('/DigitalTwin')"
          src="@/assets/img/btm-4.png"
          alt=""
          width="40"
          height="40"
        />
        <p>数字孪生</p>
      </div>
    </div>
    <div class="right">
      <p>全国免费咨询热线</p>
      <p class="phone">0371-85510169</p>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {};
  },
  methods: {},
};
</script>
    
<style scoped>
.btm {
  margin-top: 100px;
  height: 225px;
  padding: 40px 3%;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid rgba(0, 0, 0, 0.05);
  background: #fff;
}
.btm .left {
  /* display: flex;
  flex-direction: column; */
  font-size: 14px;
  width: 25%;
}
.btm .center {
  flex: 1;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #777777;
  padding: 20px 5%;
}
.btm .center > div {
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  border-right: 1px solid rgba(0, 0, 0, 0.05);
  width: 25%;
}
.btm .center > div p {
  margin-top: 10px;
  transition: all 0.4s;
}
.btm .center > div:hover p {
  color: #01aeeb;
}
.btm .right {
  width: 25%;
  color: #777777;
  padding-left: 5%;
  box-sizing: border-box;
}
.btm .right > .phone {
  color: #01aeeb;
  font-family: avant;
  font-size: 36px;
}
</style>