<template>
  <div class="top-info" @click.stop="">
    <ul v-for="(item, index) in parentList" :key="index">
      <h2>{{ item.name }}</h2>
      <li
        v-for="(Item, Index) in item.children"
        :key="Index"
        @click.stop="skip(Item)"
      >
        {{ Item.name }}
      </li>
    </ul>
  </div>
</template>
    
<script>
import handleTree from "@/utils/handleTree.js";
export default {
  name: "",
  data() {
    return { parentList: [] };
  },
  methods: {
    skip(item) {
      if (this.$route.path == "/Product/info") {
        this.$router.push({
          // path: "/Product/info",
          query: { id: item.id, name: item.name },
        });
        this.$store.state.refresh++;
      } else {
        this.$router.push({
          path: "/Product/info",
          query: { id: item.id, name: item.name },
        });
      }
    },
  },
  created() {
    this.axios({
      method: "get",
      url: "/wzn/product-categories/page",
      params: {
        pageNo: 1,
        pageSize: 100,
        // parentId: 0,
      },
    }).then((res) => {
      console.log("产品分类", res.data);
      let parentList = res.data.data.list.map((item) => {
        res.data.data.list.map((Item) => {
          if (Item.parentId == item.id) {
            if (item.children) {
              item.children.push(Item);
            } else {
              item.children = [];
              item.children.push(Item);
            }
          }
        });
        return item;
      });
      this.parentList = parentList.filter((item) => {
        return item.children && item.parentId == 0;
      });
    });
  },
};
</script>
    
<style scoped>
.top-info,
.top-info1 {
  width: 100vw;
  height: 0;
  background: rgba(255, 255, 255, 0.1);
  position: absolute;
  left: 0;
  top: 90%;
  z-index: 99;
  display: flex;
  justify-content: center;
  transform: all 0.4s;
  overflow: hidden;
  animation: drawer1 0.2s linear 1 forwards;
  pointer-events: none;
}
.top-info1 {
  background: #fff;
  color: #000;
}
.top-info ul,
.top-info1 ul {
  margin: 0 20px;
  width: 12.5%;
}
.top-info ul h2,
.top-info1 ul h2 {
  font-size: 18px;
  margin-top: 20px;
}
.top-info ul li,
.top-info1 ul li {
  cursor: pointer;
  margin-top: 15px;
  font-size: 16px;
  transition: all 0.4s;
}
.top-info ul li:hover,
.top-info1 ul li:hover {
  color: #01aeeb;
}
.top-info.show,
.top-info1.show {
  animation: drawer 0.2s linear 1 forwards;
  pointer-events: all;
}
@keyframes drawer {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 35vh;
    opacity: 1;
  }
}
@keyframes drawer1 {
  0% {
    height: 35vh;
    opacity: 1;
  }
  100% {
    height: 0;
    opacity: 0;
  }
}
</style>