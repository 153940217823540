import axios from "axios";
var http = axios.create({
  // baseURL: "/mm",
  baseURL: "https://sr2x.com/admin-api",
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    authorization: "Bearer " + "test1",
  },
});

http.interceptors.response.use(
  function (response) {
    // 对响应数据做点什么
    return response;
  },
  function (error) {
    // 对响应错误做点什么
    return Promise.reject(error);
  }
);
export default http;
