<template>
  <div class="nav">
    <img src="@/assets/img/nav-top.png" alt="" />
    <div @click="$router.push('/Pproduct')">
      <img src="@/assets/img/product.png" alt="" />
      <p>产品中心</p>
    </div>
    <div @click="$router.push('/Psolve')">
      <img src="@/assets/img/solve.png" alt="" />
      <p>解决方案</p>
    </div>
    <div class="center" @click="$router.push('/Phome')">
      <img src="@/assets/img/home.png" alt="" />
      <p>主页</p>
    </div>
    <div @click="$router.push('/Puse')">
      <img src="@/assets/img/use.png" alt="" />
      <p>应用案例</p>
    </div>
    <div @click="$router.push('/Pnews')">
      <img src="@/assets/img/news.png" alt="" />
      <p>新闻中心</p>
    </div>
  </div>
</template>
    
<script>
export default {
  name: "",
  data() {
    return {
      //   navBol: false,
    };
  },
  methods: {},
  mounted() {
    // let t = 0;
    // let p = 0;
    // window.addEventListener("scroll", () => {
    //   p = document.documentElement.scrollTop;
    //   if (p > t) {
    //     this.navBol = true;
    //     t = p;
    //   } else {
    //     this.navBol = false;
    //     t = p;
    //   }
    // });
  },
};
</script>
    
<style scoped>
.nav {
  background: #fff;
  width: 100%;
  position: fixed;
  bottom: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  padding: 0 5% 10px 5%;
  box-sizing: border-box;
}
.nav > img {
  width: 100%;
  height: auto;
  position: absolute;
  left: 0%;
  top: 0%;
  transform: translateY(-96%);
  pointer-events: none;
}
.nav > div {
  text-align: center;
}
.nav > div > img {
  width: 30px;
}
.nav > div.center > img {
  width: 50px;
  position: relative;
  top: -10px;
}
</style>