<template>
  <div class="top">
    <!-- @mouseover="$store.state.scrollY < 104 ? ($store.state.topBol = false) : ''" -->
    <img src="@/assets/img/logo.png" alt="" />
    <div class="center">
      <span @click="$router.push('/Home')">首页</span>
      <span
        @click.stop="$router.push('/Product')"
        @mouseover="$store.state.topInfoBol = true"
        @mouseout="$store.state.topInfoBol = false"
        >产品中心
        <TopInfo
          :class="$store.state.topInfoBol ? 'top-info show' : 'top-info'"
        ></TopInfo>
      </span>
      <span @click="$router.push('/Solve')">解决方案</span>
      <span @click="$router.push('/Use')">应用案例</span>
      <span @click="$router.push('/News')">新闻中心</span>
      <span @click="$router.push('/DigitalTwin')">数字孪生</span>
      <span @click="$router.push('/Regard')">关于我们</span>
    </div>

    <div class="right">
      <div class="phone">
        <img src="@/assets/img/phone.png" alt="" width="20px" />
      </div>
      <span style="margin-left: 10px">0371-85510169</span>
      <span style="margin: 0 10px">丨</span>
      <el-popover placement="bottom" width="30" trigger="hover">
        <div>
          <img src="@/assets/img/vx-qr-code.jpg" alt="" width="100%" />
        </div>
        <div class="vx" slot="reference">
          <img src="@/assets/img/vx.png" alt="" width="20px" />
        </div>
      </el-popover>
    </div>
  </div>
</template>

<script>
import TopInfo from "@/components/Pc/topInfo.vue";

export default {
  name: "",
  components: {
    TopInfo: TopInfo,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>

<style scoped>
.top {
  position: fixed;
  z-index: 99;
  width: 100%;
  padding: 0 4rem;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #fff;
}
.top > img {
  height: 40px;
}
.top > .center {
  flex: 1;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  text-align: center;
  z-index: 10;
}
.top > .center > span {
  cursor: pointer;
  flex: 1;
  text-align: center;
  padding: 3em 0;
}
.top .right {
  display: flex;
  align-items: center;
}
.phone,
.vx {
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
}
</style>
