<template>
  <div id="app">
    <div v-if="type" v-bar style="height: 100vh" id="bar">
      <div>
        <Top
          :class="
            $store.state.topBol
              ? 'animate__animated animate__fadeInDown'
              : 'animate__animated animate__fadeOutUp'
          "
        ></Top>
        <Top1
          v-show="!$store.state.topBol"
          :class="
            !$store.state.topBol
              ? 'animate__animated animate__fadeInDown'
              : 'animate__animated animate__fadeOutUp'
          "
        ></Top1>
        <keep-alive>
          <router-view v-if="$route.meta.keepAlive"></router-view>
        </keep-alive>
        <router-view v-if="!$route.meta.keepAlive"></router-view>
        <Up
          :class="
            !$store.state.topBol
              ? 'animate__animated animate__fadeInUp'
              : 'animate__animated animate__fadeOutDown'
          "
        ></Up>
        <Btm></Btm>
      </div>
    </div>
    <div v-if="!type">
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive"></router-view>
      </keep-alive>
      <router-view v-if="!$route.meta.keepAlive"></router-view>
      <PhoneBtm></PhoneBtm>
      <phoneNav></phoneNav>
    </div>
  </div>
</template>
<script>
import Top from "@/components/Pc/top.vue";
import Top1 from "@/components/Pc/top1.vue";
import Btm from "@/components/Pc/btm.vue";
import Up from "@/components/Pc/up.vue";
import PhoneBtm from "@/components/Phone/btm.vue";
import phoneNav from "@/components/Phone/nav.vue";
export default {
  name: "",
  components: {
    Top: Top,
    Top1: Top1,
    Up: Up,
    // Right: Right,
    // Cnt: Cnt,
    // Page1: Page1,
    // Page2: Page2,
    // Page3: Page3,
    // Page4: Page4,
    // Page5: Page5,
    Btm: Btm,
    PhoneBtm: PhoneBtm,
    phoneNav: phoneNav,
  },
  data() {
    return { type: false };
  },
  methods: {
    isMobile() {
      let flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      if (!flag && window.innerWidth < 700) {
        flag = true;
      }
      return flag;
    },
  },
  created() {
    // window.addEventListener("scroll", () => {
    //   if (window.scrollY > 104) {
    //     this.$store.state.topBol = false;
    //   } else {
    //     this.$store.state.topBol = true;
    //   }
    // });
  },
  mounted() {
    if (!this.type) {
      this.type = false;
      this.$router.push("/Phome");
    } else {
      this.type = true;
      this.$router.push("/");
      this.$store.state.bar = document.querySelector(".vb-content");
      this.$store.state.bar.addEventListener("scroll", (e) => {
        this.$store.state.scrollY = e.target.scrollTop;
        if (this.$store.state.scrollY > 104) {
          this.$store.state.topBol = false;
        } else {
          this.$store.state.topBol = true;
        }
      });
    }
  },
  watch: {
    "$route.path": {
      immediate: true,
      handler(newVal, oldVal) {
        console.log(this.isMobile());
        if (this.isMobile()) {
          this.type = false;
        } else {
          this.type = true;
          setTimeout(() => {
            // document.body.scrollTop = 0;
            // document.documentElement.scrollTop = 0;
            this.$store.state.bar.scrollTo({
              top: 0,
              behavior: "instant",
            });
          }, 20);
        }
      },
    },
  },
};
</script>
<style>
* {
  margin: 0;
  padding: 0;
  /* scrollbar-width: thin !important;
  scrollbar-color: #50b3ff5a #abaaaa94; */
  scrollbar-width: none;
  -ms-overflow-style: none;
  -webkit-overflow-style: none;
}
::-webkit-scrollbar {
  display: none;
  width: 0 !important;
  height: 0 !important;
  -webkit-appearance: none;
  background-color: transparent;
}

/* ::-webkit-scrollbar{
	width:10px;
	height:6px;
  background-color: #abaaaa94;
}
::-webkit-scrollbar-thumb{
	background-color:#50b3ff5a;
	border-radius:10px;
}
::-webkit-scrollbar-track{
	background-color:transparent;
} */
html {
  scroll-behavior: smooth;
  -webkit-overflow-scrolling: touch;
}
body {
  font-size: 12px;
  font-family: "Microsoft Yahei", "Helvetica Neue", Arial, sans-serif;
  color: #3e3e3e;
  overflow-x: hidden;
  background: #f1f4f4;
}
ul {
  list-style: none;
}
a{
  text-decoration: none;
  color: #c0c0c0
}
a:hover {
  color: #c81623;
}
.home canvas {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  width: 100%;
  height: 100%;
  z-index: 0;
}
.phone-head-public > img {
  width: 100%;
  height: 30vh;
  display: block;
}
.head-public > img {
  width: 100%;
  height: 80vh;
  display: block;
}
.title-public {
  margin-bottom: 30px;
}
.title-public p {
  text-align: center;
  color: #3e3e3e;
  font-size: 18px;
}
.title-public h3,
.title-public h5 {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 30px;
  color: #4e4e4e;
  font-weight: inherit;
}
.title-public h5 {
  font-size: 18px;
}
.title-public .center {
  padding: 0 30px;
}
.title-public .line-l,
.title-public .line-r {
  background: #e3e3e3;
  height: 1px;
  width: 30vw;
  position: relative;
}
.title-public .line-l::after,
.title-public .line-r::after {
  content: "";
  height: 3px;
  width: 60px;
  background: #bfbfbf;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.title-public .line-l::after {
  right: 0;
}
.title-public .line-r::after {
  left: 0;
}
.cnt-public {
  padding: 70px 0;
}
.vb > .vb-dragger {
  z-index: 5;
  width: 12px;
  right: 0;
}

.vb > .vb-dragger > .vb-dragger-styler {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  -webkit-transform: rotate3d(0, 0, 0, 0);
  transform: rotate3d(0, 0, 0, 0);
  -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  transition: background-color 100ms ease-out, margin 100ms ease-out,
    height 100ms ease-out;
  background-color: rgba(48, 121, 244, 0.1);
  margin: 5px 5px 5px 0;
  border-radius: 20px;
  height: calc(100% - 10px);
  display: block;
}

.vb.vb-scrolling-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.3);
}

.vb > .vb-dragger:hover > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
  margin: 0px;
  height: 100%;
}

.vb.vb-dragging-phantom > .vb-dragger > .vb-dragger-styler {
  background-color: rgba(48, 121, 244, 0.5);
}
.ql-video {
  width: 100%;
  height: 500px;
}
</style>
