import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: function () {
      return import("../views/Pc/Home.vue");
    },
    meta: {
      keepAlive: true, //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  {
    path: "/PHome",
    name: "PHome",
    component: function () {
      return import("../views/Phone/Home.vue");
    },
    meta: {
      keepAlive: true, //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  {
    path: "/Pproduct",
    name: "Pproduct",
    component: function () {
      return import("../views/Phone/Product/index.vue");
    },
  },
  {
    path: "/Pproduct/info",
    name: "Pproduct/info",
    component: function () {
      return import("../views/Phone/Product/info.vue");
    },
  },
  {
    path: "/Pnews",
    name: "Pnews",
    component: function () {
      return import("../views/Phone/News/index.vue");
    },
  },
  {
    path: "/Pnews/info",
    name: "Pnews/info",
    component: function () {
      return import("../views/Phone/News/info.vue");
    },
  },
  {
    path: "/Psolve",
    name: "Psolve",
    component: function () {
      return import("../views/Phone/Solve/index.vue");
    },
  },
  {
    path: "/Psolve/info",
    name: "Psolve/info",
    component: function () {
      return import("../views/Phone/Solve/info.vue");
    },
  }, 
   {
    path: "/Puse",
    name: "Puse",
    component: function () {
      return import("../views/Phone/Use/index.vue");
    },
  },
  {
    path: "/Puse/info",
    name: "Puse/info",
    component: function () {
      return import("../views/Phone/Use/info.vue");
    },
  },
  
  {
    path: "/Home",
    name: "Home",
    component: function () {
      return import("../views/Pc/Home.vue");
    },
    meta: {
      keepAlive: true, //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  // 产品中心
  {
    path: "/Product",
    name: "product",
    component: function () {
      return import("../views/Pc/Product/index.vue");
    },
  },
  {
    path: "/Product/info",
    name: "product/info",
    component: function () {
      return import("../views/Pc/Product/info.vue");
    },
  },
  // 解决方案
  {
    path: "/Solve",
    name: "solve",
    component: function () {
      return import("../views/Pc/Solve/index.vue");
    },
  },
  {
    path: "/Solve/info",
    name: "solve/info",
    component: function () {
      return import("../views/Pc/Solve/info.vue");
    },
  },
  // 应用案例
  {
    path: "/Use",
    name: "use",
    component: function () {
      return import("../views/Pc/Use/index.vue");
    },
  },
  {
    path: "/Use/info",
    name: "use/info",
    component: function () {
      return import("../views/Pc/Use/info.vue");
    },
  },
  // 新闻中心
  {
    path: "/News",
    name: "news",
    component: function () {
      return import("../views/Pc/News/index.vue");
    },
  },
  {
    path: "/News/info",
    name: "news/info",
    component: function () {
      return import("../views/Pc/News/info.vue");
    },
  },
  //数字孪生
  {
    path: "/DigitalTwin",
    name: "digitalTwin",
    component: function () {
      return import("../views/Pc/DigitalTwin.vue");
    },
    meta: {
      keepAlive: true, //true为需要缓存，false为不需要缓存（不需要的也可以不加）
    },
  },
  //关于我们
  {
    path: "/Regard",
    name: "regard",
    component: function () {
      return import("../views/Pc/Regard.vue");
    },
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    setTimeout(() => {
      // document.body.scrollTop = 0;
      // document.documentElement.scrollTop = 0;
      window.scrollTo({
        top: 0,
        behavior: "instant",
      });
    }, 20);
  },
});

export default router;
