import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "./axios/http";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "./assets/css/animation.css";
import VueCookies from "vue-cookies";
import swal from "sweetalert";
import "swiper/dist/css/swiper.min.css";
import "swiper/dist/js/swiper.min";
import Swiper from "swiper";
import Vuebar from "vuebar";

// 解决ElementUI导航栏中的vue-router在3.0版本以上重复点菜单报错问题
import Router from "vue-router";
const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err);
};

Vue.config.productionTip = false;
Vue.prototype.axios = axios;
Vue.prototype.swal = swal;
Vue.prototype.swiper = Swiper;

Vue.use(VueCookies);
Vue.use(ElementUI);
Vue.use(Vuebar);
new Vue({
  router,
  store,
  render: function (h) {
    return h(App);
  },
}).$mount("#app");
